// import VideoLinkOld from "./VideoLinkOld";

import VideoLinkServer from "./VideoLinkServer";

export default function MainBody() {

  return (
    <>

      <div className="container-fluid text-center bodyBGPic">

        <VideoLinkServer />


        {/* <VideoLinkOld vlink="https://www.youtube.com/embed/LqFQ82lnYlQ" />
        <VideoLinkOld vlink="https://www.youtube.com/embed/wNTNO7Hh1lA" />
        <VideoLinkOld vlink="https://www.youtube.com/embed/KQrldZ5p_hY" />
        <VideoLinkOld vlink="https://www.youtube.com/embed/LWkgIizQLg4" />
        <VideoLinkOld vlink="https://www.youtube.com/embed/Le50ghIOoz4" />
        <VideoLinkOld vlink="https://www.youtube.com/embed/jvgZF9A2Ixg" />
        <VideoLinkOld vlink="https://www.youtube.com/embed/CcZQGVhubxQ" />
        <VideoLinkOld vlink="https://www.youtube.com/embed/PjvmooJtaWk" />
        <VideoLinkOld vlink="https://www.youtube.com/embed/AJoiXErVVGw" />
        <VideoLinkOld vlink="https://www.youtube.com/embed/C7PQgqW1zUE" />
        <VideoLinkOld vlink="https://www.youtube.com/embed/BuwOJuMoBL4" />
        <VideoLinkOld vlink="https://www.youtube.com/embed/676CmSOyzGY" />
        <VideoLinkOld vlink="https://www.youtube.com/embed/70a6_nTu8Ks" />
        <VideoLinkOld vlink="https://www.youtube.com/embed/FbkLVk25p1M" />
        <VideoLinkOld vlink="https://www.youtube.com/embed/QpAzGGoQ5N4" />
        <VideoLinkOld vlink="https://www.youtube.com/embed/ia_yOFCWkcA" />
        <VideoLinkOld vlink="https://www.youtube.com/embed/cR0xayjqFdg" />
        <VideoLinkOld vlink="https://www.youtube.com/embed/WJqGUb1HXsQ" />
        <VideoLinkOld vlink="https://www.youtube.com/embed/QuoTriLzmz8" />
        <VideoLinkOld vlink="https://www.youtube.com/embed/a34qlgN4lk4" />
        <VideoLinkOld vlink="https://www.youtube.com/embed/5q_amTQw9bk" />
        <VideoLinkOld vlink="https://www.youtube.com/embed/XkdAIWUF78o" />
        <VideoLinkOld vlink="https://www.youtube.com/embed/7i_XY9EtsL0" />
        <VideoLinkOld vlink="https://www.youtube.com/embed/z5Zme8RMOzQ" />
        <VideoLinkOld vlink="https://www.youtube.com/embed/5F6rW-p0ufs" />
        <VideoLinkOld vlink="https://www.youtube.com/embed/plh-c2AHWWo" />
        <VideoLinkOld vlink="https://www.youtube.com/embed/4i2XUGOpYHs" />
        <VideoLinkOld vlink="https://www.youtube.com/embed/vkCos84bIRY" />
        <VideoLinkOld vlink="https://www.youtube.com/embed/gDHdz3JqK5s" />
        <VideoLinkOld vlink="https://www.youtube.com/embed/0y8AdcvY4NA" />
        <VideoLinkOld vlink="https://www.youtube.com/embed/upYR-DxWDvE" />
        <VideoLinkOld vlink="https://www.youtube.com/embed/X5qZ1TWZhAk" />
        <VideoLinkOld vlink="https://www.youtube.com/embed/bOFYp0Pnorc" />
        <VideoLinkOld vlink="https://www.youtube.com/embed/T0IXMqACHgY" />
        <VideoLinkOld vlink="https://www.youtube.com/embed/oB5g3fGOBj4" />
        <VideoLinkOld vlink="https://www.youtube.com/embed/ACqimhcn0_k" />
        <VideoLinkOld vlink="https://www.youtube.com/embed/0BWGsVbpckM" />
        <VideoLinkOld vlink="https://www.youtube.com/embed/0MNy6lGsw2U" />
        <VideoLinkOld vlink="https://www.youtube.com/embed/PRCzttF6Rtg" />
        <VideoLinkOld vlink="https://www.youtube.com/embed/1wZPhTDXjMI" /> */}

        <div className="d-block" >
          <a className="btn btn-danger youtubeRed w-75" href="https://www.youtube.com/@EaswaranEntertainment">Watch More Videos Visit Our Youtube Channel<i className="bi bi-youtube mx-2"></i>Easwaran Entertainment</a>
        </div>
      </div>

    </>
  )
};
