import FootPart from "../components/FootPart"
import HeadPart from "../components/HeadPart"
import MainBody from "../components/MainBody"

export const Home = () => {
  return (
    <>
    <HeadPart />
    <MainBody />
    <FootPart />
    </>
  )
}
