import './App.css';
import ContactUs from './pages/ContactUs';
import { Grow } from './pages/Grow';
import { Home } from './pages/Home';
import { BrowserRouter, Routes, Route } from "react-router-dom";



function App() {
  return (
    <>
    <BrowserRouter>
      <Routes>
        <Route path="/">
          <Route index element={<Home />} />
          <Route path="contact-us" element={<ContactUs />} />
          <Route path="grow" element={<Grow />} />
          <Route path="*" element={<Home />} />
        </Route>
      </Routes>
    </BrowserRouter>
    </>
  );
}

export default App;
