import { useState, useEffect } from "react";

export default function VideoLinkServer() {

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    const [currentPage, setCurrentPage] = useState(1);
    // eslint-disable-next-line
    const [postPerPage, setPostPerPage] = useState(6);


    // https://jsonplaceholder.typicode.com/posts

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch("https://easwaran-entertainment-backend-deploy-10091389336.development.catalystappsail.com/videos");
                const result = await response.json();
                setData(result);
                setLoading(false);

            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, []);

    const indexOfLastPost = currentPage * postPerPage;
    const indexOfFirstPost = indexOfLastPost - postPerPage;

    const currentPosts = data.slice(indexOfFirstPost, indexOfLastPost);

    const totalPages = Math.ceil(data.length / postPerPage);

    const paginate = (page) => setCurrentPage(page);

    if (loading) return <p>Loading...</p>;

    return (
        <>

            {currentPosts.map((post) => (
                <iframe className="m-1" width="383.4" height="216" key={post.sno} src={post.videoLink}
                    title="YouTube video player" frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            ))}

            <nav aria-label="Page Navigation">
                <ul class="pagination justify-content-center">
                    <li class="page-item"><button className="btn btn-sm btn-info border-black" disabled={currentPage === 1} onClick={() => paginate(1)}>First</button></li>
                    <li class="page-item"><button className="btn btn-sm btn-info border-black" disabled={currentPage === 1} onClick={() => paginate(currentPage - 1)}>Previous</button></li>

                    {new Array(totalPages).fill(0).map((_, index) => {
                        return (
                            <li class="page-item"><button className={currentPage === index + 1 ? "active text-bg-success btn btn-sm btn-info border-black" : "btn btn-sm btn-info border-black"} onClick={() => paginate(index + 1)} key={index + 1}>{index + 1}</button></li>

                        );
                    })}

                    <li class="page-item"><button className="btn btn-sm btn-info border-black" disabled={currentPage === totalPages} onClick={() => paginate(currentPage + 1)}>Next</button></li>
                    <li class="page-item"><button className="btn btn-sm btn-info border-black" disabled={currentPage === totalPages} onClick={() => paginate(totalPages)}>Last</button></li>
                </ul>
            </nav>
        </>
    )
}
