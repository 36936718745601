// import VideoLinkOld fr
export default function GrowBody() {

  return (
    <>

      <div className="container-fluid text-center bodyBGPic">

      <iframe className="m-1" width="383.4" height="216" src="https://www.youtube.com/embed/PjvmooJtaWk?list=PL7Z98tMN847Eq1Ua4DovJmGRgA6Pg8ffH&amp;loop=1&amp;" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>

      <iframe className="m-1" width="383.4" height="216" src="https://www.youtube.com/embed/ia_yOFCWkcA?list=PL7Z98tMN847Eq1Ua4DovJmGRgA6Pg8ffH&amp;loop=1&amp;" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>

      <iframe className="m-1" width="383.4" height="216" src="https://www.youtube.com/embed/z5Zme8RMOzQ?list=PL7Z98tMN847Eq1Ua4DovJmGRgA6Pg8ffH&amp;loop=1&amp;" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>

      <iframe className="m-1" width="383.4" height="216" src="https://www.youtube.com/embed/X5qZ1TWZhAk?list=PL7Z98tMN847Eq1Ua4DovJmGRgA6Pg8ffH&amp;loop=1&amp;" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>

      <iframe className="m-1" width="383.4" height="216" src="https://www.youtube.com/embed/ELJ_kv2i1zE?list=PL7Z98tMN847Eq1Ua4DovJmGRgA6Pg8ffH&amp;loop=1&amp;" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>

      

        {/* <VideoLinkOld vlink="https://www.youtube.com/embed/LqFQ82lnYlQ?autoplay=1&mute=1&loop=1&playlist=LqFQ82lnYlQ" />
        <VideoLinkOld vlink="https://www.youtube.com/embed/wNTNO7Hh1lA?autoplay=1&mute=1&loop=1&playlist=wNTNO7Hh1lA" />
        <VideoLinkOld vlink="https://www.youtube.com/embed/KQrldZ5p_hY?autoplay=1&mute=1&loop=1&playlist=KQrldZ5p_hY" />
        <VideoLinkOld vlink="https://www.youtube.com/embed/LWkgIizQLg4?autoplay=1&mute=1&loop=1&playlist=LWkgIizQLg4" />
        <VideoLinkOld vlink="https://www.youtube.com/embed/Le50ghIOoz4?autoplay=1&mute=1&loop=1&playlist=Le50ghIOoz4" />
        <VideoLinkOld vlink="https://www.youtube.com/embed/jvgZF9A2Ixg?autoplay=1&mute=1&loop=1&playlist=jvgZF9A2Ixg" />
        <VideoLinkOld vlink="https://www.youtube.com/embed/CcZQGVhubxQ?autoplay=1&mute=1&loop=1&playlist=CcZQGVhubxQ" />
        <VideoLinkOld vlink="https://www.youtube.com/embed/PjvmooJtaWk?autoplay=1&mute=1&loop=1&playlist=PjvmooJtaWk" />
        <VideoLinkOld vlink="https://www.youtube.com/embed/AJoiXErVVGw?autoplay=1&mute=1&loop=1&playlist=AJoiXErVVGw" />
        <VideoLinkOld vlink="https://www.youtube.com/embed/C7PQgqW1zUE?autoplay=1&mute=1&loop=1&playlist=C7PQgqW1zUE" />
        <VideoLinkOld vlink="https://www.youtube.com/embed/BuwOJuMoBL4?autoplay=1&mute=1&loop=1&playlist=BuwOJuMoBL4" />
        <VideoLinkOld vlink="https://www.youtube.com/embed/676CmSOyzGY?autoplay=1&mute=1&loop=1&playlist=676CmSOyzGY" />
        <VideoLinkOld vlink="https://www.youtube.com/embed/70a6_nTu8Ks?autoplay=1&mute=1&loop=1&playlist=70a6_nTu8Ks" />
        <VideoLinkOld vlink="https://www.youtube.com/embed/FbkLVk25p1M?autoplay=1&mute=1&loop=1&playlist=FbkLVk25p1M" />
        <VideoLinkOld vlink="https://www.youtube.com/embed/QpAzGGoQ5N4?autoplay=1&mute=1&loop=1&playlist=QpAzGGoQ5N4" />
        <VideoLinkOld vlink="https://www.youtube.com/embed/ia_yOFCWkcA?autoplay=1&mute=1&loop=1&playlist=ia_yOFCWkcA" />
        <VideoLinkOld vlink="https://www.youtube.com/embed/cR0xayjqFdg?autoplay=1&mute=1&loop=1&playlist=cR0xayjqFdg" />
        <VideoLinkOld vlink="https://www.youtube.com/embed/WJqGUb1HXsQ?autoplay=1&mute=1&loop=1&playlist=WJqGUb1HXsQ" />
        <VideoLinkOld vlink="https://www.youtube.com/embed/QuoTriLzmz8?autoplay=1&mute=1&loop=1&playlist=QuoTriLzmz8" />
        <VideoLinkOld vlink="https://www.youtube.com/embed/a34qlgN4lk4?autoplay=1&mute=1&loop=1&playlist=a34qlgN4lk4" />
        <VideoLinkOld vlink="https://www.youtube.com/embed/5q_amTQw9bk?autoplay=1&mute=1&loop=1&playlist=5q_amTQw9bk" />
        <VideoLinkOld vlink="https://www.youtube.com/embed/XkdAIWUF78o?autoplay=1&mute=1&loop=1&playlist=XkdAIWUF78o" />
        <VideoLinkOld vlink="https://www.youtube.com/embed/7i_XY9EtsL0?autoplay=1&mute=1&loop=1&playlist=7i_XY9EtsL0" />
        <VideoLinkOld vlink="https://www.youtube.com/embed/z5Zme8RMOzQ?autoplay=1&mute=1&loop=1&playlist=z5Zme8RMOzQ" />
        <VideoLinkOld vlink="https://www.youtube.com/embed/5F6rW-p0ufs?autoplay=1&mute=1&loop=1&playlist=5F6rW-p0ufs" />
        <VideoLinkOld vlink="https://www.youtube.com/embed/plh-c2AHWWo?autoplay=1&mute=1&loop=1&playlist=plh-c2AHWWo" />
        <VideoLinkOld vlink="https://www.youtube.com/embed/4i2XUGOpYHs?autoplay=1&mute=1&loop=1&playlist=4i2XUGOpYHs" />
        <VideoLinkOld vlink="https://www.youtube.com/embed/vkCos84bIRY?autoplay=1&mute=1&loop=1&playlist=vkCos84bIRY" />
        <VideoLinkOld vlink="https://www.youtube.com/embed/gDHdz3JqK5s?autoplay=1&mute=1&loop=1&playlist=gDHdz3JqK5s" />
        <VideoLinkOld vlink="https://www.youtube.com/embed/0y8AdcvY4NA?autoplay=1&mute=1&loop=1&playlist=0y8AdcvY4NA" />
        <VideoLinkOld vlink="https://www.youtube.com/embed/upYR-DxWDvE?autoplay=1&mute=1&loop=1&playlist=upYR-DxWDvE" />
        <VideoLinkOld vlink="https://www.youtube.com/embed/X5qZ1TWZhAk?autoplay=1&mute=1&loop=1&playlist=X5qZ1TWZhAk" />
        <VideoLinkOld vlink="https://www.youtube.com/embed/bOFYp0Pnorc?autoplay=1&mute=1&loop=1&playlist=bOFYp0Pnorc" />
        <VideoLinkOld vlink="https://www.youtube.com/embed/T0IXMqACHgY?autoplay=1&mute=1&loop=1&playlist=T0IXMqACHgY" />
        <VideoLinkOld vlink="https://www.youtube.com/embed/oB5g3fGOBj4?autoplay=1&mute=1&loop=1&playlist=oB5g3fGOBj4" />
        <VideoLinkOld vlink="https://www.youtube.com/embed/ACqimhcn0_k?autoplay=1&mute=1&loop=1&playlist=ACqimhcn0_k" />
        <VideoLinkOld vlink="https://www.youtube.com/embed/0BWGsVbpckM?autoplay=1&mute=1&loop=1&playlist=0BWGsVbpckM" />
        <VideoLinkOld vlink="https://www.youtube.com/embed/0MNy6lGsw2U?autoplay=1&mute=1&loop=1&playlist=0MNy6lGsw2U" />
        <VideoLinkOld vlink="https://www.youtube.com/embed/PRCzttF6Rtg?autoplay=1&mute=1&loop=1&playlist=PRCzttF6Rtg" />
        <VideoLinkOld vlink="https://www.youtube.com/embed/1wZPhTDXjMI?autoplay=1&mute=1&loop=1&playlist=1wZPhTDXjMI" /> */}

        <div className="d-block" >
          <a className="btn btn-danger youtubeRed w-75" href="https://www.youtube.com/@EaswaranEntertainment">Watch More Videos Visit Our Youtube Channel<i className="bi bi-youtube mx-2"></i>Easwaran Entertainment</a>
        </div>
      </div>

    </>
  )
};
