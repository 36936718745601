
export default function FootPart() {
    
  return (
    <>
    <footer className="text-center text-bg-dark">
      <div className="d-flex justify-content-center p-2">
        <a href="https://www.facebook.com/EaswaranEntertainment" className="me-3"><i className="bi bi-facebook"></i></a>
        <a href="https://EaswaranEntertainment.blogspot.com" className="me-3"> <i className="bi bi-rss"></i></a>
        <a href="https://www.youtube.com/@EaswaranEntertainment" className="me-3"><i className="bi bi-youtube"></i></a>

      </div>
      <div className="d=flex justify-content-center m-2">
        <a href="/index.html" className="me-3"> Terms & Conditions</a>
        <a href="/index.html" className="me-3"> Privacy Policy</a>
        <a href="/index.html" className="me-3"> Accessiblity Statement</a>
      </div>
      <div className="text-center text-bg-secondary">
        <p className="mb-0">&copy;2025 by <a href="/grow" className="text-white text-decoration-none">Easwarn Entertainment</a>, Designed by <a href="https://www.linkedin.com/in/muthu-ammew" className="text-bg-danger">Muthu</a>
        </p>
      </div>

    </footer>
    </>
  )
}
