import Contact from "../components/Contact";
import FootPart from "../components/FootPart";
import HeadPart from "../components/HeadPart";

export default function ContactUs() {
    return (
        <>
        <HeadPart />
        <Contact />
        <FootPart />
        </>
    )
}
