import FootPart from "../components/FootPart"
import GrowBody from "../components/GrowBody"
import HeadPart from "../components/HeadPart"

export const Grow = () => {
  return (
    <>
    <HeadPart />
    <GrowBody />
    <FootPart />
    </>
  )
}
