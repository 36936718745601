import { Link } from "react-router-dom";

export default function HeadPart() {

  return (
    <>
      <div className="container-fluid">
        <div className="row m-0">
          <div className="col-3 col-lg-2 px-0">
            <Link to="/">
              <img className="img-fluid" src="./pictures/logo.png" alt="logo" />
            </Link>
          </div>
          <div className="col banner">

          </div>
        </div>
      </div>

      <nav className="navbar bg-dark navbar-expand-lg" data-bs-theme="dark">
        <div className="container-fluid">
          <div className="d-flex justify-content-between">
            <Link className="navbar-brand fs-6 d-none d-sm-block d-lg-none d-xl-block ps-1" to="/">Easwaran Entertainment</Link>

            <Link className="btn btn-outline-success active me-2" aria-current="page" to ="/">Home</Link>
            <a className="btn btn-danger youtubeRed me-2" href="https://www.youtube.com/@EaswaranEntertainment"><i className="bi bi-youtube"></i>Youtube Channel</a>
          </div>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle ms-lg-2 me-2" href="www.google.com" role="button" data-bs-toggle="dropdown"
                  aria-expanded="false"><i className="bi bi-cookie"></i>
                  Social Networks
                </a>
                <ul className="dropdown-menu">
                  <li><a className="dropdown-item" href="https://www.facebook.com/EaswaranEntertainment"><i className="bi bi-facebook"></i>Facebook</a></li>
                  <li><a className="dropdown-item" href="https://EaswaranEntertainment.blogspot.com"><i className="bi bi-rss"></i>Blogger</a></li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li><a className="dropdown-item me-2" href="https://www.facebook.com/PiyoosTech"><i className="bi bi-messenger"></i>Chat(Via FB page Message button)</a></li>
                </ul>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/contact-us"><i className="bi bi-door-open"></i>Contact Us</Link>
              </li>
            </ul>
            <form className="d-flex" role="search">
              <input className="form-control me-2 border-danger" type="search" placeholder="Search" aria-label="Search" />
              <button className="btn btn-outline-danger me-2" type="submit">Search</button>
            </form>
          </div>
        </div>
      </nav>
    </>
  )
}
